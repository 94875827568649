.textarea {
    /* dimentions */
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    min-width: 300px;
    min-height: 150px;

    /* padding */
    padding: 10px;

    /* margins */
    margin: 0px;

    /* border */
    border: none;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.07);
}

    .textarea:focus {
        /* border */
        outline: none;
    }

    /* special scrollbar */

    /* width */
    .textarea::-webkit-scrollbar {
        width: 5px;
        cursor: pointer !important;
    }
    
    /* Track */
    .textarea::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0);
        cursor: pointer !important;
    }

    /* Handle */
    .textarea::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer !important;
    }

    /* Handle on hover */
    .textarea::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.25);
        cursor: pointer !important;
    }