.node-input-requirecontent-container {

    /* dimentions */
    width: 15px;
    height: 15px;

    /* margins */
    margin-right: 5px;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border */
    border: solid;
    border-width: 1px;
    border-radius: 2px;
    border-color: rgba(256, 256, 256, 0.1);

    /* font */
    font-size: 12px;
    font-weight: 400;
}

    .require-content {
        /* font */
        font-weight: 800;
        color: rgba(1, 120, 255, 0.87);
    }

    .allow-stream {
        /* font */
        color: rgba(255, 255, 255, 0.507);
    }

    .not-required {
        /* font */
        color: rgba(0, 0, 0, 0);
    }