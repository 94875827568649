.modal-overlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    font-size: 12px;
    display: flex;
    background-color: var(--side-bar-background);
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 2rem;
    border-radius: 4px;
}

.delete-node-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.button-container .delete {

    /* dimentions */
    width: auto;
    height: auto;

    /* padding */
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    /* font */
    font-size: 12px;
    color: white;
    white-space: nowrap;

    /* background */
    box-shadow: none;
    background:rgb(225, 83, 83);
}

.button-container .cancel {

    /* padding */
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    /* font */
    font-size: 12px;
    color: white;
    white-space: nowrap;

    /* background */
    box-shadow: none;
    background:rgb(38, 38, 38);
}