.recent-usage-container {
    /* dimentions */
    width: 100%;
    height: 100%;

    /* padding */
    overflow: auto;
}

    .recent-usage {

        /* position */
        position: absolute;

        /* dimentions */
        width: 100%;
        height: auto;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

        .recent-usage-item {

            /* dimentions */
            width: 100%;
            height: auto;

            /* padding */
            padding: 5px;
            padding-left: 15px;
            padding-right: 15px;

            /* margins */
            margin-top: 5px;
            margin-bottom: 5px;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            /* border */
            border-radius: 10px;

            /* background */
            background-color: var(--slightly-lighter);
            box-shadow: var(--edit-menu-container-shadow);
        }

            .recent-usage-item-button {
                /* dimentions */
                width: 20px;
                height: 20px;

                /* margins */
                margin-right: 5px;

                /* pre-hover */
                opacity: 0.25;
            }

                .recent-usage-item-button:hover {
                    /* post hover */
                    opacity: 0.5;
                    cursor: pointer;
                }

                .recent-usage-item-button-collapsed {
                    transform: rotate(-90deg);
                }

            .recent-usage-item-info {

                /* dimentions */
                width: 100%;
                height: auto;

                /* flex options */
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                /* font */
                color: var(--weak-text);
                font-size: 14px;
            }

                .recent-usage-item-model {
                    margin-left: 15px;
                }

                .recent-usage-item-cost {
                    margin-left: auto;
                }

                    .recent-usage-item-cost::first-letter {
                        margin-right: 5px;
                        opacity: 0.25;
                    }


            .recent-usage-item-io {
                /* dimentions */
                width: 100%;
                height: auto;

                /* margins */
                margin-top: 10px;
            }

                .recent-usage-item-input {
                    /* dimentions */
                    width: 100%;
                    height: auto;

                    /* margins */
                    margin-bottom: 15px;
                    
                }

                    .recent-usage-item-input-label {
                        /* dimentions */
                        width: 100%;
                        height: auto;
                    
                        /* margins */
                        margin-bottom: 5px;
                    
                        /* font */
                        color: var(--weak-text);
                        font-size: 14px;
                    }

                    .recent-usage-item-input-content {
                        /* dimentions */
                        width: 100%;
                        height: auto;
                    
                        /* margins */
                        margin-bottom: 5px;
                    
                        /* font */
                        color: var(--medium-text);
                        font-size: 14px;
                    }



/* special scrollbar */

/* width */
.recent-usage-container::-webkit-scrollbar {
    display: none;
}