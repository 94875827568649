:root {
    --usageListExpandedWidth: 400px;
    --usageListExpansionTime: 0.3s;
  }

.usage-container {
    /* dimentions */
    width: var(--usageListExpandedWidth);
    height: 100%;

    /* overflow */
    overflow: hidden;

    /* transitions */
    transition: width var(--usageListExpansionTime) ease;
}

.usage-container-collapsed {
    width: 0px;
}

    .usage {
        
        /* position */
        position: absolute;

        /* dimentions */
        width: var(--usageListExpandedWidth);
        height: 100%;

        /* overflow */
        overflow-x: hidden;
        overflow-y: hidden;
        
        /* padding */
        padding: 15px;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* border */
        border-top: solid;
        border-top-width: 1px;
        border-top-color: var(--nodemaps-border);

        /* background */
        background-color: var(--nodemaps-background);
    }

        .usage-section-label {
            /* dimentions */
            width: 100%;
            height: auto;

            /* margins */
            margin-bottom: 10px;

            /* font */
            color: var(--medium-text);
            font-size: 14px;
        }