
    /* overflow: hidden; */

    /* margins & padding */
    /* margin: 15px;
    padding: 10px; */

    /* border */
    /* border-radius: 10px; */

    /* background */
    /* background-color: var(--control-nodes-container-background);
    box-shadow: var(--control-nodes-container-shadow); */

.edit-menu-section-expanded {

    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.edit-menu-section-collapsed {
    height: 0px;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

    .add-node-button {
        /* dimentions */
        width: auto;
        max-width: 50%;
        height: auto;
        flex-grow: 1;

    /* margins */
    margin: 5px;

    /* padding */
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* border */
    box-shadow: var(--add-node-button-shadow);
    border-radius: 10px;

    /* color */
    background-color: var(--add-node-button-background);
    background-image: none;
    opacity: 0.65;
}

.add-node-button:hover {

    /* color */
    opacity: 1;
}

    .add-node-button-icon {

        /* dimentions */
        width: 15px;
        height: 15px;

        /* margins */
        margin-right: 10px;
    }

    .add-node-button-text {

            /* font options */
            color: var(--add-node-button-text);
            font-size: 14px;
        }


        /* .dropdown {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
          }
          
          .dropdown-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-weight: normal;
            background-image: none;
            box-shadow: none;
            background-color: var(--input-variable-type-dropdown-background);
            border: 1px solid #333;
          }
          
          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
          }
          
          .dropdown-content button {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
          }

          
         .dropdown.open .dropdown-content{
            display: block;
            top: 110%;
            width: 100%;
            height: auto;
          }


          .arrow {
            transition: transform 0.3s ease;
          }
          
          .arrow-up {
            transform: rotate(180deg);
          }




          .dropdown-content ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
          }
          
          .dropdown-content li {
            padding: 12px 16px;
            text-decoration: none;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          
          .dropdown-content li:hover {
            background-color: #f1f1f1;
          }
          
          .add-node-list-item-icon {
            margin-right: 8px;
          }
          



    .add-node-dropdown {
        width: 100%;
        height: 2rem;
    }


    #light .add-node-dropdown {

    }


    #dark .dropdown-content {
        background-color: #222;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    #dark .dropdown-content li {
        color: white;
    }


    #dark .dropdown-content li:hover {
        background-color: #1ef076;
        color: black;
      } */