.menu-and-content-container {
  
    display: flex;
    width: auto;
}

.menu {

    /* dimentions */
    width: auto;
    height: 100%;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    /* z index */
    z-index: 2;
}

    .menu-buttons {

        /* dimentions */
        width: auto;
        height: 100%;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* background */
        background-color: var(--side-bar-background);

        /* borders */
        border-right: solid;
        border-width: 1px;
        border-color: var(--side-bar-border);
    }

        .menu-button {

            /* dimentions */
            width: 100%;
            height: auto;

            /* padding */
            padding: 10px;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            /* pre-hover */
            opacity: 0.35;
        }

        .menu-button:hover {

            /* post-hover */
            background-color: var(--side-bar-button-hover);
            opacity: 0.65;
            cursor: pointer;
        }

        .menu-button-selected {

            background-color: var(--side-bar-button-selected);
            opacity: 0.75;
            cursor: pointer;
        }

        .menu-button-selected:hover {

            background-color: var(--side-bar-button-selected-hover);
            opacity: 0.75;
            cursor: pointer;
        }

            .menu-button-label {

                /* dimentions */
                width: auto;
                height: auto;

                /* margin */
                margin-bottom: 10px;

                /* flex options */
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                white-space: nowrap;

                /* font */
                color: var(--strong-text);
                font-size: 12px;
            }

            .menu-button-image {

                /* dimentions */
                width: 30px;
                height: 30px;
            }

    .menu-content-and-save-button-container {

        /* position */
        position: absolute;
        left: 100%;

        /* dimentions */
        width: auto;
        height: 100%;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

        .menu-content {

            /* position */
            position: relative;

            /* dimentions */
            width: auto;
            height: 100%;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .save-button-container {
            position: relative;
            width: 1px;
        }

          @keyframes menuContentSlideOut {
            0% {
              opacity: 1;
              transform: translateX(0);
            }
            100% {
              display: none;
              opacity: 0;
              transform: translateX(10px);
            }
          }
         
          .menu-content-slide-out {
            animation: menuContentSlideOut 300ms ease-out forwards;
          }



        