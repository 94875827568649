.tooltip {
    /* dimentions */
    height: auto;
    width: auto;
}

.tooltip-hover-icon {

    /* dimentions */
    height: 12px;
    width: 12px;

    /* padding */
    padding: 0px;

    /* margins */
    margin-left: 5px;
    margin-right: 5px;

    /* pre-hover */
    opacity: 0.5;
}

.tooltip-hover-icon:hover {

    transition: all 0.25s;

    /* post-hover */
    opacity: 1;
    cursor: pointer;
}

.tooltip-content {
    /* position */
    position: fixed;
  
    /* dimensions */
    width: auto;
    height: auto;
  
    /* z-index */
    z-index: 999;
  
    /* opacity and visibility */
    opacity: 0;
    visibility: hidden;
  
    /* transition */
    transition: opacity 0.25s, visibility 0.25s;
  }

.tooltip-content.visible {
    opacity: 1;
    visibility: visible;
}

.tooltip-text {

    /* dimentions */
    width: 200px;

    /* padding */
    padding: 10px;

    /* body */
    border-radius: 5px;
    background-color: rgb(128, 128, 128);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    /* font */
    font-size: 14px;
}