.zoom-indicator {

    /* position */
    position: absolute;
    bottom: 15px;
    left: -130px;

    /* dimentions */
    width: 115px;
    height: 40px;

    /* padding */
    padding: 5px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    /* background */
    background-color: rgba(24, 24, 24, 1);
    box-shadow: var(--chat-menu-messages-container-shadow);

    /* border */
    border-radius: 5px;

    /* pre-hover */
    opacity: 0.5;
    transition: all 0.25s;
}

    .zoom-indicator:hover {
        /* post-hover */
        opacity: 1;
    }

    .zoom-indicator-level-display {
        
        /* font */
        color: var(--medium-text);
    }

    .zoom-indicator-button {

        /* dimentions */
        width: 15px;
        height: 15px;
    }

        .zoom-indicator-button:hover {

            /* post hover */
            cursor: pointer;
            transform: scale(1.35);
        }