.node-container {

    /* position */
    position: absolute;
    left: 0px;
    top: 0px;

    /* dimentions */
    width: auto;
    height: auto;

    /* prevent selection of text */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.node {

    /* position */
    position: relative;

    /* dimentions */
    width: auto;
    height: auto;

    /* flex-options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    .node-name {

        /* position */
        position: relative;

        /* dimentions */
        width: 100%;
        height: auto;

        /* padding */
        padding: 10px;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        /* font */
        color: var(--node-name-text);
        text-align: center;

        /* cursor */
        cursor: all-scroll;
    }
    
    .node-name-icon {

        /* dimentions */
        width: 20px;
        height: 20px;

        /* margins */
        margin-right: 10px;

        /* color */
        opacity: 0.5;
    }

    .node-name-text {

        /* font */      font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .node-info-link {
        
        /* font */      font-size: 12px;
        /* margins */   margin-left: 10px;
        /* pre-hover */ opacity: 0.5;
    }

    .node-delete-button {

        /* position */
        position: absolute;
        right: 0px;
        bottom: 10px;
        
        /* dimentions */
        width: 10px;
        height: 10px;

        /* opacity */
        opacity: 0.25;
    }

        .node-delete-button:hover {
            cursor: pointer;
            opacity: 0.75;
        }

    .node-body {

        /* dimentions */
        width: auto;
        height: auto;

        /* flex-options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* background */
        background-color: var(--node-body-background);

        /* border */
        border: solid;
        border-width: 2px !important;
        border-color: transparent;
        border-radius: 5px;
        box-shadow: var(--node-body-shadow)
    }

    .node-body-pending {
        border: solid;
        border-color: rgba(256, 256, 256, 0.25);
    }

    .node-body-streaming {
        border: solid;
        border-color: rgba(256, 256, 256, 0.25);
    }

    .node-body-processed {
        border: solid;
        border-color: #007BFFBF;
    }

    .node-body-error {
        border: solid;
        border-color: rgba(256, 100, 100, 0.25);
    }

    .node-body-upper {

        /* dimentions */
        width: 100%;
        height: auto;

        /* flex-options */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .node-body-lower {
        /* dimentions */
        width: 100%;
        height: auto;

        /* flex-options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

        .node-inputs {

            /* dimentions */
            width: auto;
            height: auto;
            
            /* padding */
            padding: 5px;
            padding-top: 10px;  /* <-- this is to balance out the empty space from a missing 'add node' button

            /* flex-options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .node-outputs {

            /* dimentions */
            width: auto;
            height: auto;
            
            /* padding */
            padding: 5px;
            padding-top: 10px;  /* <-- this is to balance out the empty space from a missing 'add node' button

            /* flex-options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }

            .node-input,
            .node-output {

                /* margins */
                margin-top: 5px;
                margin-bottom: 5px;

                /* flex-options */
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

                .node-input-marker {

                    /* dimentions */
                    width: 10px;
                    height: 10px;

                    /* margins */
                    margin-left: 2px;
                    margin-right: 5px;

                    /* color */
                    background-color: var(--node-input-marker);
                    border-radius: 100%;
                    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);

                    /* opacity */
                    opacity: 0.75;
                }

                    .node-input-marker:hover {
                        cursor: pointer;
                        opacity: 1;
                        transform: scale(1.25, 1.25);
                    }

                .node-output-marker {

                    /* dimentions */
                    width: 12px;
                    height: 12px;

                    /* margins */
                    margin-left: 2px;
                    margin-right: 2px;

                    /* color */
                    background-color: var(--node-output-marker);
                    border-radius: 100%;

                    /* opacity */
                    opacity: 0.75;
                }

                    .node-output-marker:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                
                .node-input-name,
                .node-output-name {
                    /* dimentions */
                    width: auto;
                    min-width: 50px;

                    /* margins */
                    margin-left: 2px;
                    margin-right: 2px;

                    /* padding */
                    padding-top: 2.5px;
                    padding-bottom: 2.5px;
                    padding-left: 0px;
                    padding-right: 0px;

                    /* border */
                    border-radius: 3px;
                    box-shadow: inset 0px 0px 2.5px rgba(0, 0, 0, 0.3);

                    /* font */
                    color: var(--medium-text);
                    font-family: monospace;
                    text-align: center;
                }

                .node-input-name:disabled,
                .node-output-name:disabled {
                    color: var(--weak-text);
                }

                .node-input-name::-webkit-input-placeholder {
                    color: rgba(256, 256, 256, 0.2);
                }

                .node-input-name:-moz-placeholder {
                    color: rgba(256, 256, 256, 0.2);
                }
                .node-input-name::-moz-placeholder {
                    color: rgba(256, 256, 256, 0.2);
                }
                .node-input-name:-ms-input-placeholder {
                    color: rgba(256, 256, 256, 0.2);
                }

                .node-input-requireContent,
                .node-output-requireContent {
                    /* dimentions */
                    width: 15px;
                    width: 15px;

                    /* margins */
                    margin-left: 2px;
                    margin-right: 2px;
                }

                .delete-node-input-button-container,
                .delete-node-output-button-container {
                    /* dimentions */
                    width: 10px;
                    height: 10px;

                    /* margins */
                    margin-left: 2px;
                    margin-right: 2px;

                    /* flex-options */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                    .delete-node-input-button,
                    .delete-node-output-button {
                        /* dimentions */
                        width: 100%;
                        height: 100%;

                        /* color */
                        opacity: 0.25;
                    }

                        .delete-node-input-button:hover,
                        .delete-node-output-button:hover {
                            cursor: pointer;
                            opacity: 0.5;
                        }

            
            .add-node-input-button-container,
            .add-node-output-button-container{
                /* dimentions */
                width: 100%;
                height: 10px;

                /* flex-options */
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

                .add-node-input-button,
                .add-node-output-button{
                    /* dimentions */
                    width: 15px;
                    height: 15px;

                    /* color */
                    opacity: 0.5;
                }

                    .add-node-input-button:hover,
                    .add-node-output-button:hover {
                        cursor: pointer;
                        opacity: 1;
                    }

/* ========== NODE MIDDLE ========== */

.node-setting-checkbox {
    /* dimentions */    width: 15px; height: 15px;
    /* margins */       margin-top: 5px; margin-bottom: 5px;
}

.node-middle {
    /* dimentions */
    width: auto;
    height: auto;

    /* padding */
    padding: 5px;

    /* flex-options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.horizontal-group {

    /* dimentions */
    width: auto;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.vertical-group {

    /* dimentions */
    width: 100%;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.natural-language-model-middle {

    /* dimentions */
    width: auto;
    height: auto;

    /* padding */
    padding-top: 10px;
    padding-bottom: 10px;

    /* flex-options */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.memory-middle {

    /* dimentions */
    width: auto;
    height: auto;
    max-width: 600px;
}

    .node-setting-container {
        /* dimentions */
        width: auto;
        height: auto;

        /* padding */
        padding: 0px;

        /* margins */
        margin: 5px;

        /* flex-options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
        .node-setting-label {
            /* padding */
            margin-bottom: 5px;

            /* font */
            color: var(--weak-text) !important;
            font-size: 14px;
        }

        #dark .node-setting-label {
            color:white;
        }

    .input-variable-select,
    .output-variable-select {
        
        /* dimentions */
        width: auto;
        height: 30px;

        /* padding */
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 5px;
        padding-bottom: 5px;

        /* background */
        background-color: var(--io-variable-select);

        /* border */
        border: none;
        border-radius: 5px;

        /* margins */
        margin-right: 5px;
        margin-left: 5px;
    }

    .input-variable-select-option,
    .output-variable-select-option {
        background-color: rgba(0, 0, 0, 0.75);
    }

        .text-content-box {
            /* dimentions */
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            min-width: 100px;
            min-height: 100px;

            /* padding */
            padding: 10px;

            /* margins */
            margin: 0px;

            /* border */
            border: none;
            border-radius: 5px;
            box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.07);
        }

            .text-content-box:focus {
                /* border */
                outline: none;
            }

            /* special scrollbar */

            /* width */
            .text-content-box::-webkit-scrollbar {
                width: 5px;
                cursor: pointer !important;
            }
            
            /* Track */
            .text-content-box::-webkit-scrollbar-track {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0);
                cursor: pointer !important;
            }

            /* Handle */
            .text-content-box::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.1);
                cursor: pointer !important;
            }

            /* Handle on hover */
            .text-content-box::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.25);
                cursor: pointer !important;
            }

    .model-select {
        
        /* dimentions */
        width: auto;
        height: 30px;

        /* padding */
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 5px;
        padding-bottom: 5px;

        /* margins */
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;

        /* background */
        background-color: var(--model-select-dropdown);

        /* flex-options */
        display: flex;
        flex-grow: 1;

        /* border */
        border: none;
        border-radius: 5px;

        /* margins */
        margin-right: 5px;
        margin-left: 5px;
    }

    .max-tokens-textbox {

        /* dimentions */
        height: 30px;
        max-width: 10ch;

        /* padding */
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        padding-left: 0px;
        padding-right: 0px;

        /* margins */
        margin: 0px;

        /* flex options */
        display: flex;
        flex-grow: 1;

        /* border */
        border-radius: 3px;
        box-shadow: inset 0px 0px 2.5px rgba(0, 0, 0, 0.3);

        /* font */
        font-family: monospace;
        text-align: center;
    }

    .model-param-slider-container {

        /* dimentions */
        width: auto;
        height: auto;

        /* padding */
        padding: 5px;

        /* flex-options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    .stop-sequences-textbox {

        /* dimentions */
        height: 30px;
        width: auto;

        /* padding */
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        padding-left: 0px;
        padding-right: 0px;

        /* margins */
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;

        /* flex options */
        display: flex;
        flex-grow: 1;

        /* border */
        border-radius: 3px;
        box-shadow: inset 0px 0px 2.5px rgba(0, 0, 0, 0.3);

        /* font */
        font-family: monospace;
        text-align: center;
    }

.custom-code-textbox {
    min-width: 200px;
    min-height: 200px;
    position: relative;
    z-index: 0 !important;
}

/* ========== NODE BOTTOM ========== */

.node-progress-bar-container {
    /* position */      position: relative;
    /* dimentions */    width: 100%; height: 3px;
    /* margins */       margin-bottom: -1px;
    /* border */        border-radius: 5px;
    /* overflow */      overflow: hidden;
}

    .node-progress-bar {
        /* dimentions */    width: 0%; height: 100%;
        /* background */    background-color: var(--node-input-marker);
    }

    @keyframes marching-ants {
        from {
            background-position: 100% 0;
        }
    }
    
    .node-progress-bar-marching {

        /* dimensions */
        width: 100%;
        height: 100%;

        /* background */
        background-image: linear-gradient(90deg,
        transparent 6.25%, var(--node-input-marker) 6.25%,
        var(--node-input-marker) 12.5%, transparent 12.5%,
        transparent 18.75%, var(--node-input-marker) 18.75%,
        var(--node-input-marker) 25%, transparent 25%,
        transparent 31.25%, var(--node-input-marker) 31.25%,
        var(--node-input-marker) 37.5%, transparent 37.5%,
        transparent 43.75%, var(--node-input-marker) 43.75%,
        var(--node-input-marker) 50%, transparent 50%,
        transparent 56.25%, var(--node-input-marker) 56.25%,
        var(--node-input-marker) 62.5%, transparent 62.5%,
        transparent 68.75%, var(--node-input-marker) 68.75%,
        var(--node-input-marker) 75%, transparent 75%,
        transparent 81.25%, var(--node-input-marker) 81.25%,
        var(--node-input-marker) 87.5%, transparent 87.5%,
        transparent 93.75%, var(--node-input-marker) 93.75%,
        var(--node-input-marker) 100%, transparent 100%
        );

        background-size: 200% 100%;
        animation: marching-ants 1.5s linear infinite;
    }

.node-message-container-relative {
    /* position */      position: relative;
    /* dimentions */    width: 100%; height: 0px;
}

    .node-message-container-absolute {
        /* position */      position: absolute;
        /* dimentions */    width: 100%; height: auto;
        /* padding */       padding-left: 5%; padding-right: 5%;
    }

        .node-generic-message {
            /* dimentions */    width: auto; height: auto;
            /* padding */       padding: 15px;
            /* font */          font-size: 14px; color: rgba(255, 255, 255, 0.5); text-align: center; word-break: break-all; line-height: 150%;
        }

        .node-error-message {
            /* dimentions */    width: auto; height: auto;
            /* padding */       padding: 15px;
            /* font */          font-size: 14px; color: rgba(252, 83, 83, 0.75); word-break: break-all; line-height: 150%;
        }

        .node-warning-message {
            /* dimentions */    width: auto; height: auto;
            /* padding */       padding: 15px;
            /* font */          font-size: 14px; color: rgba(252, 212, 83, 0.589); word-break: break-all; line-height: 150%;
        }

/* ========== LOG VIEWER ========== */

.log-viewer-container {

    /* dimentions */
    width: 100%;
    height: auto;

    /* margins */
    margin-top: 25px;

    /* padding */
    padding-left: 25px;
    padding-right: 25px;
}