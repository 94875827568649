.publish-to-web-button {
    
    /* dimentions */
    width: auto;
    height: 100%;

    /* margins & padding */
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: rgb(99, 125, 255);

    /* border */
    border: none;
    border-radius: 5px;
    
    /* font-color */
    color: var(--strong-text);
    font-size: 12px;
}