.edit-menu-container {
    /* dimentions */
    width: 350px;
    height: 100%;

    border-top: solid;
    border-width: 1px;
    border-color: #0000001a
}

 .edit-menu-container input {
    border-radius: 4px;
    width: 100%;
 }

 #light .edit-menu-container input {

 }

 #dark .edit-menu-container input {
    color: white;
    border: 0.0625rem solid #373A40;
    background: #25262b;
 }


    .edit-menu {
        /* position */
        position: absolute;
        overflow: hidden;

        /* dimentions */
        width: 100%;
        height: 100%;

        /* padding */
        padding-right: 5px;
        padding-left: 5px;

        /* margin */
        /* margin-top: 2rem; */

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* background */
        background-color: var(--edit-menu-background);
        backdrop-filter: var(--edit-menu-blur);
    }

.scroll-container {

    /* dimentions */
    height: auto;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
}

    .scroll-content {
        /* position */
        position: absolute;
        left: 0px;
        top: 0px;

        /* dimentions */
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

.edit-menu-section-container {

    overflow: hidden;

    /* margins & padding */
    margin: 15px;
    padding: 10px;

    /* border */
    border-radius: 10px;

    /* background */
    background-color: var(--edit-menu-container-background);
    box-shadow: var(--edit-menu-container-shadow);

    /* font */
    color: var(--medium-text);
}

.edit-menu-section-label {

    /* dimentions */
    width: 100%;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* font */
    font-size: 14px;

    /* color */
    opacity: 0.35;
}

.edit-menu-section-label:hover {
    cursor: pointer;
    opacity: 0.75;
}

    .edit-menu-section-label-line {
        /* dimentions */
        width: 100%;
        height: 1px;

        /* margins */
        margin-left: 20px;
        margin-right: 20px;

        /* color */
        background-color: var(--edit-menu-section-label-line);
    }

    .edit-menu-section-label-icon {
        /* dimentions */
        width: 20px;
        height: 20px;

        /* margins */
        margin-right: 5px;
    }

        .edit-menu-section-label-icon-collapsed {
            transform: rotate(-90deg);
        }

    .edit-menu-section-label-text {
        /* font */
        color: var(--edit-menu-section-label-text);
        white-space: nowrap;
    }

.edit-menu-section {

    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.edit-menu-section-collapsed {
    height: 0px;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}


/* special scrollbar */

/* width */
.scroll-content::-webkit-scrollbar {
    width: 0px;
}
  
/* Track */
.scroll-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
}

/* Handle */
.scroll-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

/* Handle on hover */
.scroll-content::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
}
