.user-balance-display {
    /* margins */       margin-right: 10px;
    /* pre-hover */     opacity: 0.5;
}

.user-balance-display-low {
    /* font */          color: rgb(255, 73, 73); opacity: 1;
}

.user-balance-display:hover {
    /* post-hover */    opacity: 0.85; cursor: pointer;
}