/* special scrollbar */

/* width */
.nodemaps-list::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
.nodemaps-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
}

/* Handle */
.nodemaps-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
}

/* Handle on hover */
.nodemaps-list::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.25);
}

:root {
    --nodeMapsListExpandedWidth: 300px;
    --nodeMapsListExpansionTime: 0.3s;
  }

.nodemaps-container {
    /* dimentions */
    width: var(--nodeMapsListExpandedWidth);
    height: 100%;

    /* overflow */
    overflow: hidden;

    /* transitions */
    transition: width var(--nodeMapsListExpansionTime) ease;
}

.nodemaps-container-collapsed {
    width: 0px;
}

    .nodemaps {
        
        /* position */
        position: absolute;

        /* dimentions */
        width: var(--nodeMapsListExpandedWidth);
        height: 100%;

        /* overflow */
        overflow-x: hidden;
        overflow-y: hidden;
        
        /* padding */
        padding-top: 25px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* border */
        border-top: solid;
        border-top-width: 1px;
        border-top-color: var(--nodemaps-border);

        /* background */
        background-color: var(--nodemaps-background);
    }

        .create-nodemap-button {

            /* dimentions */
            width: auto;
            height: auto;

            /* padding */
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;

            /* margins */
            margin-bottom: 5px;

            /* flex options */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            /* background */
            background-color: transparent;
            background-image: none;
            box-shadow: none;

            /* border */
            border: solid;
            border-color: var(--weak-text);
            border-width: 1px;
            border-radius: 5px;

            /* color */
            opacity: 0.5;

            transition: all 0.25s;
        }

            .create-nodemap-button:hover {
                opacity: 1;
            }

            .create-nodemap-button-icon {

                /* dimentions */
                width: 25px;
                height: 25px;

                /* margins */
                margin-right: 10px;

                /* color */
                filter: invert(0) !important;
            }

            .create-nodemap-button-text {

                /* font options */
                color: var(--create-nodemap-button-text);
                font-size: 16px;
            }

        .nodemaps-list {

            /* dimentions */
            width: 100%;
            height: 100%;

            /* padding */
            padding-left: 5px;
            padding-right: 5px;

            /* overflow */
            overflow-x: hidden;
            overflow-y: auto;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

            .nodemaps-section-label {
                /* dimentions */
                width: 100%;
                height: auto;

                /* padding */
                padding-left: 15px;

                /* font */
                color: var(--weak-text); font-size: 12px;
            }

            .user-maps {
                /* dimentions */
                width: 100%;
                height: auto;

                /* margins */
                margin-top: 25px;
            }

            .demo-maps {
                /* dimentions */
                width: 100%;
                height: auto;
                
                /* margins */
                margin-top: 35px;
            }


        /* Animations

        .fade-in {
            animation: fadeIn 0.3s linear forwards;
          }
          
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          } */