.chat-messages-display-container {

    /* dimentions */
    min-width: 300px;
    min-height: 150px;

    /* make resizable */
    resize: both;
    overflow: auto;
    overflow-x: hidden;

    /* border */
    border-radius: 5px;

    /* background */
    background-color: var(--chat-menu-messages-container-background);
    box-shadow: var(--chat-menu-messages-container-shadow);

    /* Resize Handle */
    background: var(--chat-menu-messages-container-background);
    box-shadow: 0 0 0 2px var(--chat-menu-messages-container-background), 0 0 0 4px var(--color-of-your-resize-handle);
}

.memory-chat-message-container {

    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 15px;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    /* border */
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--chat-message-container-border-top);
}

/* individual chat message */
.memory-chat-message-container-ai {

    /* background */
    background-color: var(--slightly-lighter);

    /* flex options */;
    align-items: flex-start;

    /* border */
    border-top: none;
}

    .memory-chat-message-role-time-container {
        /* dimentions */
        width: auto;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

        .memory-chat-message-time {
            /* margins */
            margin-left: 5px;
            margin-bottom: 10px;

            /* font */
            color: var(--chat-menu-time-text);
            font-size: 11px;
        }
        
        .memory-chat-message-role {
            /* margins */
            margin-bottom: 10px;

            /* font */
            color: var(--chat-menu-role-text);
            font-size: 11px;
        }

    .memory-chat-message-content {
        /* dimentions */
        width: auto;

        /* padding */
        padding-left: 10px;

        /* font */
        color: var(--chat-menu-message-text);
        font-size: 14px;
        overflow-wrap: break-word;
    }

        .memory-chat-message-image {
            /* dimentions */    width: 100%;
            /* border */        border-radius: 5px;
            /* color */         filter: invert(0);
            /* opacity */       opacity: 0.75;
        }

        .memory-chat-message-audio {
            /* dimentions */    min-width: 100%; max-width: 100%; height: 25px;
            /* border */        border-radius: 5px;
            /* color */         filter: invert(0.97);
            /* opacity */       opacity: 0.75;
        }

/* CUSTOM SCROLLBAR */

/* For Webkit browsers like Chrome and Safari */
.chat-messages-display-container::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
}

.chat-messages-display-container::-webkit-scrollbar-track {
    background: none; /* color of the tracking area */
}

.chat-messages-display-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
}