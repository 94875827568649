.add-image-node-button {
    /* dimageentions */
    width: auto;
    max-width: 50%;
    height: auto;
    flex-grow: 1;

    /* margins */
    margin: 10px;

    /* padding */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    /* shadow */
    box-shadow: var(--add-node-button-shadow);

    /* color */
    background-image: linear-gradient(to right, #7e9117, #a06a2d, #c2932f, #8aad2a);
    opacity: 1;
}

.add-image-node-button:hover {
    /* color */
    opacity: 1;
}

    .add-image-node-button-icon {

        /* dimageentions */
        width: 15px;
        height: 15px;

        /* margins */
        margin-right: 10px;
    }

    .add-image-node-button-text {

        /* font options */
        color: var(--add-node-button-text);
        font-size: 12px;
    }