:root {

/* pallete */
--matte-black: rgb(24, 24, 25);
--slightly-lighter: rgba(256, 256, 256, 0.026);
--moderately-lighter: rgba(256, 256, 256, 0.05);

/* general */
--strong-text: rgb(230, 230, 230);
--medium-text: rgb(255, 255, 255, 0.75);
--weak-text: rgba(241, 241, 241, 0.5);

--background-color: rgba(0, 0, 0, 0.999);

--strong-shadow: rgba(0, 0, 0, 0);
--medium-shadow: rgba(0, 0, 0, 0);
--weak-shadow: rgba(0, 0, 0, 0);

/* top bar */
--top-bar-background: var(--matte-black);
--logo-text: rgba(255, 255, 255, 1);

/* side bar */
--side-bar-background: var(--matte-black);
--side-bar-border: rgba(255, 255, 255, 0.1);

    /* side bar buttons */
    --side-bar-button-hover: rgba(0, 0, 0, 0.05);
    --side-bar-button-selected: rgba(0, 0, 0, 0.15);
    --side-bar-button-selected-hover: rgba(0, 0, 0, 0.15);

        /* list of nodemaps by user */
        --create-nodemap-button-text: rgba(255, 255, 255, 1);
        --nodemaps-border: rgba(255, 255, 255, 0.089);
        --nodemaps-background: var(--matte-black);
        --nodemaps-list-item-border: rgba(255, 255, 255, 0.08);
        --nodemaps-list-item-name: rgba(255, 255, 255, 0.9);
        --nodemaps-list-item-last-edited: rgba(255, 255, 255, 0.25);

        /* edit menu */
        --edit-menu-background: var(--matte-black);
        --edit-menu-blur: blur(15px);

            /* nodemap io variables */
            --edit-menu-section-label-text: rgb(255, 255, 255);
            --edit-menu-section-label-line: rgb(255, 255, 255);
            --edit-menu-container-background: var(--slightly-lighter);
            --edit-menu-container-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

                --input-variable-type-dropdown-background: rgba(255, 255, 255, 0.07);
                --input-variable-name-textbox-background: rgba(255, 255, 255, 0.07);

                --add-node-button-text: var(--strong-text);
                --add-node-button-background: rgba(255, 255, 255, 0.07);
                --add-node-button-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);


/* sandbox */
--sandbox-background: #28292e;

    --node-name-text: rgba(255, 255, 255, 0.50);
    --node-body-background: rgb(24, 24, 24);
    --node-body-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
    --node-input-marker: rgba(0, 93, 253, 0.75);
    --node-output-marker: rgba(0, 93, 253, 1);
    --node-setting-label: rgba(0, 0, 0, 0.5);
    --io-variable-select: rgba(255, 255, 255, 0.07);
    --model-select-dropdown: rgba(255, 255, 255, 0.07);

/* io panel */

--io-panel-background: var(--matte-black);
--io-panel-blur: blur(15px);

    --io-variable-field-label: var(--medium-text);
    --text-input-background: rgba(15, 15, 15, 1);
    --text-input-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

    --chat-menu-label: var(--medium-text);
    --clear-chat-history-button-background: rgba(0, 0, 0, 0.1);

    --chat-menu-messages-container-background: var(--slightly-lighter);
    --chat-menu-messages-container-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

    --chat-message-container-border-top: rgba(255, 255, 255, 0.05);
    --chat-message-container-background: var(--slightly-lighter);
    --chat-message-container-background-ai: var(--moderately-lighter);;
    --chat-menu-time-text: var(--weak-text);
    --chat-menu-role-text: var(--weak-text);
    --chat-menu-message-text: var(--medium-text);

    --chat-menu-submit-container-background: var(--moderately-lighter);
    --chat-menu-submit-container-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

}

html {
    color: var(--strong-text);
}

input {
    background-color: rgba(0, 0, 0, 0.25);
    color: var(--weak-text);
}

select {
    color: var(--weak-text);
}

textarea {
    background-color: rgba(0, 0, 0, 0.25);
    color: var(--medium-text);
}

img {
    filter: invert(1);
}
