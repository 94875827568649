.usage-chart {
    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: 200px;

    /* padding */
    padding: 5px;
    padding-top: 35px;

    /* margins */
    margin-bottom: 25px;

    /* flex options */
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: flex-end;

    /* border */
    border-radius: 10px;

    /* background */
    background-color: var(--slightly-lighter);
    box-shadow: var(--edit-menu-container-shadow);
}

    .chart-bar-container {

        /* position */
        position: relative;

        /* dimentions */
        width: 100%;

        /* margins */
        margin-left: 5px;
        margin-right: 5px;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        transition: all 1s;
    }

        .day-cost {

            /* dimentions */
            width: 100%;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            /* font */
            color: var(--weak-text);
            font-size: 12px;
        }

        .bar {

            /* dimentions */
            width: 100%;
            height: 100%;
            min-height: 2px;

            /* margins */
            margin-top: 5px;
            margin-bottom: 5px;

            /* background */
            background-color: rgba(59, 153, 75, 0.9);
        }

        .day-name {

            /* dimentions */
            width: 100%;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            /* font */
            color: var(--weak-text);
            font-size: 12px;
        }