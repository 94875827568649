.input-variable,
.output-variable {

    /* dimentions */
    width: 100%;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    .input-variable-show-settings-icon,
    .output-variable-show-settings-icon {
        /* dimentions */ width: 15px; height: 15px;
        /* color */      opacity: 0.5;
    }

    .input-variable-show-settings-icon:hover,
    .output-variable-show-settings-icon:hover {
        /* post hover */    cursor: pointer; opacity: 0.75;
    }

    .input-variable-settings,
    .output-variable-settings {
        /* dimentions */    width: 100%; height: auto;
        /* margins */       margin-right: 5px; margin-left: 5px;
        /* flex options */  display: flex; flex-direction: column; justify-content: center; align-items: stretch;
    }

    .input-variable-basic-settings,
    .output-variable-basic-settings {
        /* dimentions */    width: 100%; height: auto;
        /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
    }

        .input-variable-type-dropdown,
        .output-variable-type-dropdown {
            
            /* dimentions */
            width: 105px;
            height: 30px;

            /* padding */
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 5px;
            padding-bottom: 5px;

            /* background */
            background-color: var(--input-variable-type-dropdown-background);

            /* border */
            border: none;
            border-radius: 5px;

            /* margins */
            margin-right: 5px;
            margin-left: 5px;

            /* font */
            font-size: 10px;
        }

        .input-variable-type-dropdown-option,
        .output-variable-type-dropdown-option {
            background-color: rgba(0, 0, 0, 0.75);
        }

        .input-variable-name,
        .output-variable-name {
            
            /* dimentions */
            width: 95px !important;
            height: 30px;

            /* flex options */
            display: flex;

            /* padding */
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 5px;
            padding-bottom: 5px;

            /* background */
            background-color: var(--input-variable-name-textbox-background) !important;

            /* border */
            border: none;
            border-radius: 5px;

            /* font */
            font-size: 10px;
            color:rgba(255, 255, 255, 0.75) !important;
        }

        .input-variable-name:disabled,
        .output-variable-name:disabled {
            opacity: 0.4;
        }

    .input-variable-advanced-settings,
    .output-variable-advanced-settings {
        /* dimentions */    width: 100%; height: auto;
        /* padding */       padding: 5px;
        /* margins */       margin-top: 5px; margin-bottom: 10px;
        /* flex options */  display: flex; flex-direction: column; justify-content: center; align-items: center;
        /* border */        border: solid; border-color: rgba(255, 255, 255, 0.13); border-width: 1px; border-radius: 5px;
    }

        .require-show-input-variable,
        .require-show-output-variable {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .message-name-and-role {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .input-variable-display-type,
        .output-variable-display-type {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .input-variable-min-max-words,
        .output-variable-min-max-words {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .input-variable-min-max-value,
        .output-variable-min-max-value {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .message-name-and-role {
            /* dimentions */    width: 100%; height: auto;
            /* flex options */  display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;
        }

        .input-variable-setting,
        .output-variable-setting {
            /* dimentions */    width: 100%;
            /* margins */       margin-top: 5px; margin-bottom: 5px; margin-left: 5px; margin-right: 5px;
            /* flex options */  display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
        }

        .input-variable-setting-vertical,
        .output-variable-setting-vertical {
            /* dimentions */    width: 100%;
            /* margins */       margin-top: 5px; margin-bottom: 5px; margin-left: 5px; margin-right: 5px;
            /* flex options */  display: flex; flex-direction: column; justify-content: center; align-items: flex-start;
        }

            .input-variable-setting-label,
            .output-variable-setting-label {
                /* margin */    margin: 2.5px;
                /* font */      font-size: 12px; color: var(--weak-text); white-space: nowrap;
            }

            .input-variable-setting-checkbox,
            .output-variable-setting-checkbox {

                /* margins */
                margin: 2.5px !important;

                /* dimentions */
                width: 12px !important;
                height: 12px !important;
            }

            .input-variable-setting-dropdown,
            .output-variable-setting-dropdown {
                
                /* dimentions */
                width: 100px;
                height: auto;

                /* padding */
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 5px;
                padding-bottom: 5px;

                /* margins */
                margin: 2.5px !important;

                /* background */
                background-color: var(--input-variable-type-dropdown-background) !important;

                /* border */
                border: none;
                border-radius: 5px;

                /* margins */
                margin-right: 5px;
                margin-left: 5px;

                /* font */
                font-size: 10px;
            }

            .input-variable-setting-dropdown-option,
            .output-variable-setting-dropdown-option {
                /* background */
                background-color: rgba(0, 0, 0, 0.75) !important;
            }

            .input-variable-setting-input,
            .output-variable-setting-input {
                
                /* dimentions */
                width: 40px !important;
                height: auto;

                /* margins */
                margin: 2.5px !important;

                /* flex options */
                display: flex;

                /* padding */
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 5px;
                padding-bottom: 5px;

                /* background */
                background-color: var(--input-variable-name-textbox-background);

                /* border */
                border: none;
                border-radius: 5px;

                /* font */
                font-size: 10px;
            }

            .input-variable-setting-text-input,
            .output-variable-setting-text-input {
                
                /* dimentions */
                width: 100%;
                height: auto;

                /* margins */
                margin: 2.5px !important;

                /* flex options */
                display: flex;

                /* padding */
                padding-left: 8px;
                padding-right: 8px;
                padding-top: 5px;
                padding-bottom: 5px;

                /* background */
                background-color: var(--input-variable-name-textbox-background);

                /* border */
                border: none;
                border-radius: 5px;

                /* font */
                font-size: 10px;
            }
    

    .input-variable-delete-icon,
    .output-variable-delete-icon {
        
        /* dimentions */
        width: 10px;
        height: 10px;

        /* margin */
        margin-left: 2px;

        /* opacity */
        opacity: 0.25;
    }

        .input-variable-delete-icon:hover,
        .output-variable-delete-icon:hover {
            cursor: pointer;
            opacity: 0.75;
        }