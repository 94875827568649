.textbox {

    /* dimentions */
    height: 30px;
    max-width: 10ch;

    /* padding */
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    padding-left: 0px;
    padding-right: 0px;

    /* margins */
    margin: 0px;

    /* flex options */
    display: flex;
    flex-grow: 1;

    /* border */
    border-radius: 5px !important;
    box-shadow: inset 0px 0px 2.5px rgba(0, 0, 0, 0.3);

    /* font */
    font-family: monospace;
    text-align: center;
}