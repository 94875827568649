.nodemap-info {
    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 25px;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nodemap-info-label {
    /* dimentions */
    width: 100%;
    height: auto;

    /* margins */
    margin-top: 15px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    /* font */
    font-size: 12px;
}

.nodemap-name-textbox {
    text-align: center;
    background-color: var(--slightly-lighter) !important;
    border: none !important;
}