.nodemaps-list-item {

    /* dimentions */
    width: 100%;
    height: 70px;

    /* margins & padding */
    padding: 15px;

    /* overflow */
    overflow-x: visible;
    overflow-y: visible;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    /* border */
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--nodemaps-list-item-border);

    /*animations */
    opacity: 1;
    transform: translateY(0);
    /* transition: opacity 0.3s ease, transform 0.3s ease; */

}


  .deleted-item-animation {
    animation: fadeOut 0.3s linear forwards, slideUp 0.3s linear forwards;
  }
  
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slideUp {
    0% {
      height: 70px;
      margin-bottom: 0;
    }
    100% {
      height: 0;
      margin-bottom: -30px; /* Adjust this value based on the total margin and padding of the .nodemaps-list-item */
    }
  }


    /* Animations */

    .fade-in {
        animation: fadeIn 0.3s linear forwards;
      }
      
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }


  /* .nodemaps-list-item.fade-out {
    opacity: 0;
  } */
  
  /* .nodemaps-list-item.slide-down {
    transform: translateY(100%);
  } */



    .nodemaps-list-item-info {

        /* dimentions */
        width: auto;
        height: 100%;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
    }

        .nodemaps-list-item-name {
            /* font options */
            font-size: 14px;
            text-decoration: none;
            color: var(--nodemaps-list-item-name);
        }

        .nodemaps-list-item-name:hover {
          /* post-hover */  cursor: pointer; text-decoration: underline;
        }
        
        .nodemaps-list-item-last-edited {
            /* font options */
            font-size: 12px;
            text-decoration: none;
            color:var(--nodemaps-list-item-last-edited);
        }

    .nodemaps-list-item-buttons {

        /* dimentions */
        width: auto;
        height: 100%;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
    }

        .nodemaps-list-item-delete {
            /* dimentions */
            width: 20px;
            height: 20px;

            /* color */
            opacity: 0.25;
            filter: invert(0);
        }

        .nodemaps-list-item-delete-disabled {
          /* dimentions */
          width: 20px;
          height: 20px;

          /* color */
          opacity: 0.05;
          filter: invert(0);
      }

            .nodemaps-list-item-delete:hover {
                cursor: pointer;
                opacity: 0.5;
            }

        .nodemaps-list-item-share {
            /* dimentions */
            width: 20px;
            height: 15px;

            /* color */
            opacity: 0.25;
            filter: invert(0);
        }

            .nodemaps-list-item-share:hover {
                cursor: pointer;
                opacity: 0.5;
            }