/* special scrollbar */

/* width */
.io-panel::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
.io-panel::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
}

/* Handle */
.io-panel::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
}

/* Handle on hover */
.io-panel::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* special scrollbar */

/* width */
.text-input-field::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
.text-input-field::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
}

/* Handle */
.text-input-field::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
.text-input-field::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

:root {
    --io-panelListExpandedWidth: 350px;
    --io-panelListExpansionTime: 0.3s;
  }

.io-panel-container {

    /* position */
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

    /*dimentions */
    width: auto;
    height: 100%;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.show-hide-io-panel {
    
    /* position */
    position: absolute;
    left: -50px;
    top: 5px;

    /* dimentions */
    width: 40px;
    height: 40px;

    /* color */
    opacity: 0.2;
}

    .show-hide-io-panel:hover {
        transition: all 0.5s;
        opacity: 0.5;
        cursor: pointer;
    }

.io-panel-collapsable {

    /* dimentions */
    width: var(--io-panelListExpandedWidth);
    height: 100%;

    /* overflow */
    overflow-x: hidden;

    /* transitions */
    transition: width var(--io-panelListExpansionTime) ease;
}

.io-panel-collapsable-collapsed {
    width: 0px;
}

    .io-panel {
        
        /* position */
        position: absolute;

        /* dimentions */
        width: var(--io-panelListExpandedWidth);
        height: 100%;

        /* overflow */
        overflow-x: hidden;
        overflow-y: auto;
        
        /* padding */
        padding: 20px;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* border */
        border-top: solid;
        border-top-width: 1px;
        border-top-color: rgba(255, 255, 255, 0.089);

        /* background */
        background-color: var(--io-panel-background);
        backdrop-filter: var(--io-panel-blur);

        /* font */
        color: var(--strong-text);
    }

        .io-panel-group {

            /* dimentions */
            width: 100%;
            height: auto;
        }

            .io-variable-field {

                /* dimentions */
                width: 100%;
                height: auto;

                /* margin */
                margin-top: 10px;
                margin-bottom: 10px;

                /* flex options */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
            }

            .label-and-length {
                /* dimentions */    width: auto; height: auto;
                /* flex options */  display: flex; flex-direction: row; justify-content: center; align-items: center;
            }

                .io-variable-field-label-and-clear-button {
                    /* dimentions */    width: 100%; height: auto;
                    /* flex options */  display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;
                }

                .io-variable-field-label {
                    /* dimentions */ width: 100%; height: auto;
                    /* margins */ margin-bottom: 10px;
                    /* font */ font-size: 12px; color: var(--io-variable-field-label); white-space: nowrap;
                }

                .io-variable-field-min-max {
                    /* dimentions */ width: 100%; height: auto;
                    /* flex options */  display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;
                    /* margins */ margin-left: 5px; margin-bottom: 10px;
                    /* font */ font-size: 12px; color: var(--io-variable-field-label);
                }

                .io-variable-field-min-max-over {
                    /* dimentions */ width: 100%; height: auto;
                    /* flex options */  display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;
                    /* margins */ margin-left: 5px; margin-bottom: 10px;
                    /* font */ font-size: 12px; color: rgba(255, 100, 100, 1);
                }

                .io-variable-clear-button {
                    /* font */          color: var(--medium-text); font-size: 14px;
                    /* pre-hover */     opacity: 0.5;
                }

                .io-variable-clear-button:hover {
                    /* post-hover */    opacity: 1.0; cursor: pointer; color: rgba(255, 100, 100, 1);
                }

                .text-input-field {

                    /* dimentions */
                    width: 100%;
                    height: 100%;
                    min-height: 50px;
                    max-height: 300px;

                    /* padding */
                    padding: 10px;

                    /* background */
                    background-color: var(--slightly-lighter);
                    box-shadow: var(--text-input-shadow);

                    /* border */
                    border: none;
                    outline: none;
                    border-radius: 10px;

                    /* font */
                    color: var(--strong-text);
                    font-family: 'Ubuntu';
                    font-size: 14px;
                    line-height: 150%;

                    /* resizing options */
                    resize: vertical;
                }

                .number-input-field {

                    /* dimentions */
                    width: 100%;
                    height: auto;

                    /* padding */
                    padding: 10px;

                    /* background */
                    background-color: var(--slightly-lighter);
                    box-shadow: var(--text-input-shadow);

                    /* border */
                    border: none;
                    outline: none;
                    border-radius: 10px;

                    /* resizing options */
                    resize: vertical;
                }

                .image-input-field-container {
                    /* position */      position: relative;
                    /* dimentions */    width: 100%; height: auto; min-height: 50px;
                    /* background */    background-color: var(--moderately-lighter);
                    /* border */        border-radius: 10px;
                    /* overflow */      overflow: hidden;
                    /* pre-hover */     opacity: 0.75;
                }

                .image-input-field-container:hover {
                    /* post-hover */    opacity: 1; cursor: pointer;
                }

                    .image-input-field-placeholder {
                        /* position */      position: absolute;
                        /* dimentions */    width: 100%; height: 100%;
                        /* flex options */  display: flex; flex-direction: row; justify-content: center; align-items: center;
                        /* font */          color: var(--weak-text);
                    }

                    .image-input-field-placeholder-icon {
                        /* dimentions */    width: 20px; height: 20px;
                        /* margins */       margin-right: 5px;
                        /* color */         opacity: 0.5;
                    }

                    .image-input-field-upload {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        padding: 0px;
                        margin: 0px;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .image-input-field-image {
                        /* position */      position: relative;
                        /* dimentions */    width: 100%; height: auto;
                        /* color */         filter: invert(0) !important;
                    }

                .audio-input-field-container {
                    /* position */      position: relative;
                    /* dimentions */    width: 100%; height: auto;
                    /* flex options */  display: flex; flex-direction: column; justify-content: center; align-items: center;
                    /* background */    background-color: var(--moderately-lighter);
                    /* border */        border-radius: 10px;
                    /* overflow */      overflow: hidden;
                    /* pre-hover */     opacity: 0.75;
                }

                .audio-input-field-container:hover {
                    /* post-hover */    opacity: 1; cursor: pointer;
                }

                    .audio-input-field-placeholder {
                        /* position */      position: relative;
                        /* dimentions */    width: 100%; height: 100%; min-height: 50px;
                        /* flex options */  display: flex; flex-direction: row; justify-content: center; align-items: center;
                        /* font */          color: var(--weak-text);
                    }

                    .audio-input-field-placeholder-icon {
                        /* dimentions */    width: 20px; height: 20px;
                        /* margins */       margin-right: 5px;
                        /* color */         opacity: 0.5;
                    }

                    .audio-input-field-upload {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        padding: 0px;
                        margin: 0px;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .audio-input-field-audio {
                        /* position */      position: relative;
                        /* dimentions */    width: 100% !important; height: 35px !important;
                        /* color */         filter: invert(0.9) !important;
                        /* background */    background-color: transparent;
                        /* z-index */       z-index: 1;
                    }

        .io-panel-submit-container {

            /* dimentions */
            width: 100%;
            height: auto;

            /* margins */
            margin-top: 15px;
            margin-bottom: 15px;

            /* padding */
            padding: 10px;

            /* flex options */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

            .io-panel-submit-container-line {
                /* dimentions */    width: 100%; height: 1px;
                /* background */    background-color: rgba(256, 256, 256, 0.1);
            }

            .io-panel-submit-button {
                /* dimentions */    width: auto; height: auto;
                /* margins */       margin-left: 25px; margin-right: 25px;
                /* padding */       padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;
                /* font */          font-size: 14px;
                /* pre-hover */     opacity: 0.8;
            }

            .io-panel-submit-button:hover {
                /* post-hover */    opacity: 1; cursor: pointer;
            }

