.log-viewer {
    width: 100%;
    max-width: 100%;
    height: 100px;

    overflow: hidden;
    overflow-y: auto;

    /* make resizable */
    resize: vertical;

    /* border */
    border-radius: 5px;

    /* background */
    background-color: rgba(24, 24, 24, 1);
    box-shadow: var(--chat-menu-messages-container-shadow);

    /* pre-hover */
    opacity: 0.5;
    transition: opacity 0.25s;
}

    .log-viewer:hover {
        /* post-hover */
        opacity: 0.75;
    }

    .log-viewer-content {

        /* position */
        position: absolute;
        
        /* dimentions */
        width: 100%;
        height: auto;
    }