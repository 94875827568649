@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu';
  font-size: 16px;
}

* {
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  margin: 0px;
  box-sizing: inherit;
  position: relative;
}

#root {
  /* height: 100vh; */
  width: 100%;
}

a {
  color: #909296;
  font-size: 1rem;
}


label {
  text-align: left;
  padding: 0.25rem 0;
}


input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}


.app-root {
  display: flex;
  flex-direction: column;
  width: 100VW;
  height: 100VH;
}

#topBar-container {
  background: #ddd;
}


#main-container {
  display: flex;
  height: 100%;
}


#light #detail{
  background: white;
}


#dark #detail {
  background: #333;
}




/* a div who's only perpose is to grow vertically */
.grow-vertically
{
  height: 100%;
  width: auto;

  /* flex options */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;

  overflow-x: visible;
}

/* a div who's only perpose is to fill 100% of a parent container */
.lock-size-absolute
{
  height: 100%;
  width: 100%;

  position: absolute;

  top: 0px;
  left: 0px;
}






/* login / forgot password and register pages styling */
.signIn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  background: #1A1B1E;
  color:white;
}


.register-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  background: #1A1B1E;
  color:white;
}

.password-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  background: #1A1B1E;
  color:white;
  font-size: 1rem;
}

.confirm-email-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  background: #1A1B1E;
  color:white;
  font-size: 1rem;
}

.auth-title {
  margin-bottom: 1rem;
}


.signIn-container input, .register-container input {
  color: white;
  border: 0.0625rem solid #373A40;
  background: #25262b;
  border-radius: 4px;
}


input:focus-visible {
  outline: rgb(110, 244, 110) auto 1px;
}


.input-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0;
}

.link-container {
  margin-top: 1rem;
  text-align:center;
}

.auth-form-container a {
  font-size: 1rem;
}


.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:#26282c;
}


.auth-form-container {
  width: 35rem;
  padding: 5rem;
  border-radius: 10px;
  margin: 0.5rem;
  /* box-shadow:  29px 29px 58px #111214,
             -29px -29px 58px #232428; */
  box-shadow: 20px 14px 11px 4px rgb(15 15 15 / 13%);
}


.password-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.error-msg {
  color: #f66262;
  font-size: 0.8em;
  position: absolute;
  top: 1rem;
  right: 0;
  /* margin-top: 4px; */
}


.success-msg {
  color: #63ed81;
  font-size: 0.8em;
  position: absolute;
  top: 1rem;
  right: 0;
  /* margin-top: 4px; */
}


#recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}




/* buttons */

.auth-button {
  margin-top: 1rem;
}

.auth-button.register{
  margin-top: 0;
}


.auth-button:disabled {
  background-image: none;
  box-shadow: none;
  background-color: #cccccc;
  color: #999999;
  cursor: 'no-drop';
}


button {
  border: none;
  font-weight: bold;
  font-size: 1rem;
  /* background-image: linear-gradient(45deg, #12c63c 0%, #01f039 100%); */
  padding: 1rem;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 11px 0 rgb(49 196 190 / 35%);
  background-size: 300% 100%;

  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}



button:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}


button:focus {
  outline: none;
}