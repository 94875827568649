.custom-audio-player {
    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: rgba(0, 0, 0, 0.7);

    /* border */
    border-radius: 5px;

    cursor: default;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

    .play-pause-container {
        /* dimentions */
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        /* background */
        background-color: rgb(34, 86, 255);

        /* border */
        border-radius: 100%;
    }

    .play-pause-container:hover {
        /* post-hover */
        cursor: pointer;
    }

        .play-icon {
            /* dimentions */
            width: 60%;
            height: 60%;

            /* position */
            position: relative;
            left: 8%;
        }

        .pause-icon {
            /* dimentions */
            width: 60%;
            height: 60%;
        }

    .audio-track-container {
        /* dimentions */
        width: 100%;
        height: 25px;

        /* margins */
        margin-left: 10px;
        margin-right: 10px;
    }

        .audio-track-container > wave {
            width: 100% !important;
            height: 100% !important;
        }
    

    .file-download-button {
        /* dimentions */
        height: 35px;
        width: 20px;
        overflow: hidden;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* pre-hover */
        opacity: 0.25;
    }

    .file-download-button:hover {
        /* post-hover */
        opacity: 0.75;
        cursor: pointer;
    }

        .file-download-icon {
            /* dimentions */
            height: 100%;
            width: 35px;
        }