.top-bar {
    /* dimentions */
    width: 100%;
    height: 65px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* color */
    color: var(--strong-text);
    background-color: var(--top-bar-background);
}

    .logo-and-name {

        /* dimentions */
        width: auto;
        height: auto;

        /* position */
        position: absolute;
        left: 25px;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        /* color */
        color: var(--logo-text);
    }

        .logo {
            /* dimentions */
            width: 25px;
            height: 25px;

            /* margins */
            margin-right: 5px;

            /* color */
            filter: invert(100%)
        }

        .name {
            /* font options */
            font-family: ubuntu;
            font-size: 20px;
            font-weight: 400;
        }

        .beta-tag {
          /* padding */
          padding: 5px;

          /* margin */
          margin-left: 10px;
          
          /* background */
          background-color: rgba(63, 100, 202, 0.726);

          /* border */
          border-radius: 5px;

          /* font */
          font-size: 12px;
          font-weight: 600;
        }

    .user-profile-container {

        /* dimentions */
        width: auto;
        height: auto;

        /* position */
        position: absolute;
        right: 25px;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }




    .switch {
        position: relative;
        display: inline-block;
        /* border-radius: 100px; */
        width: 3rem;
        height: 1.5rem;
      }
      
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        border-radius: 100px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #4a4b4b;
        transition: 0.4s;
      }
      
      .slider:before {
        position: absolute;
        border-radius: 100px;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        left: 0px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
      }
      
      input:checked + .slider {
        background-color: #ccc;
      }
      
      input:checked + .slider:before {
        transform: translateX(26px);
      }
      
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }