.image-display {
    /* dimentions */    width: 100%; height: auto; aspect-ratio : 1 / 1; overflow: hidden;
    /* margins */       margin-top: 10px; margin-bottom: 10px;
    /* border */        border-radius: 10px;
}

.image-display-background {
    /* dimentions */    width: 100%; height: 100%;
    /* border */        border: dashed; border-width: 1px; border-radius: 5px; border-color: rgba(0, 0, 0, 0.5);
    /* flex options */  display: flex; flex-direction: column; justify-content: center; align-items: center;
    /* font */          color: var(--weak-text); font-weight: 200;
}

.image-display-image {
    /* position */      position: absolute; left: 0px; top: 0px;
    /* dimentions */    width: 100%; height: auto;
    /* color */         filter: invert(0);
    /* pre-hover */     opacity: 0.5;
}

.image-display-image:hover {
    /* post-hover */    opacity: 0.8;
}