.save-button {

    /* position */
    position: relative;
    top: 10px;
    left: 10px;

    /* dimentions */
    width: auto;
    height: 30px;

    /* padding */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: rgba(0, 0, 0, 0);
    background-image: none;

    /* border */
    border: solid;
    border-width: 1px;
    border-color: rgb(1, 228, 228);
    border-radius: 5px;
    box-shadow: none;

    /* font */
    color: rgb(1, 219, 219);
    font-size: 14px;

    /* opacity */
    opacity: 0.25;

    /* cursor */
    cursor: 'not-allowed';
}

.save-button-out-of-date {

    /* position */
    position: relative;
    top: 10px;
    left: 10px;

    /* dimentions */
    width: auto;
    height: 30px;

    /* padding */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: rgb(56, 84, 206);

    /* border */
    border: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 5px;

    /* font */
    color: white;
    font-size: 14px;

    /* opacity */
    opacity: 0.85;

    /* cursor */
    cursor: pointer;
}