/* snackbar styling */
.snackbar {
    display: flex;
    width: 28rem;
    /* position: absolute; */
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    padding: 1.5rem;
    border-radius: 4px;
    opacity: 0; /* initially set opacity to 0 */
    transform: translateY(100%); /* initially set translateY to 100% */
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    z-index:100;
    /* animation-name: slideIn;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; */
    /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2); */
  }


  .snackbar.show {
    opacity: 1;
    /* transform: translateY(0); */
    animation: slideIn 0.3s ease-out forwards;
  }

  .snackbar.hide {
    animation: slideOut 0.3s ease-out forwards;
  }




  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  
  

  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  
  
  
  
  .snackbar.error {
    background: rgb(33 32 38);
    border-left: 8px solid #953131;
  }
  
  .snackbar.success {
    /* background: rgb(34, 222, 34); */
    background: rgb(33 32 38);
    border-left: 8px solid rgb(34, 222, 34);
  }
  
  .snackbar-message {
    font-weight: normal;
    color:white;
    font-family:'Ubuntu', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  
  .snackbar-button {
    /* position: absolute; */
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border: none;
    background: white;
    font-size: 24px;
    color: black;
    line-height: 1;
    cursor: pointer;
    transition: transform 0.2s ease;
  }