.context-menu {
    background: rgb(47, 47, 49);
    /* width: 400px; */
    /* height: auto; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
    z-index: 999;
}

.context-menu ul {
    height: auto;
    list-style: none;
    padding-inline-start: 0;

    font-size: 12px;
}

.context-menu ul li {
    padding: 1rem;
}

.context-menu ul li:hover {
   background: rgba(255, 255, 255, 0.096);
   color: var(--strong-text);
   cursor: pointer;
}