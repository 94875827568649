.code-editor-container {
    width: 500px;
    height: 500px;
    min-width: 250px;
    min-height: 125px;

    /* make resizable */
    resize: both;
    overflow: auto;
    overflow-x: hidden;

    /* padding */
    padding: 10px;

    /* margins */
    margin: 0px;

    /* border */
    border: none;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.07);
    background-color: rgba(0, 0, 0, 0.25);
}

textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}