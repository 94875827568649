.dropdown {
        
    /* dimentions */
    width: auto;
    height: 30px;

    /* padding */
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;

    /* margins */
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    /* background */
    background-color: var(--model-select-dropdown);

    /* flex-options */
    display: flex;
    flex-grow: 1;

    /* border */
    border: none;
    border-radius: 5px;

    /* margins */
    margin-right: 5px;
    margin-left: 5px;
}

.dropdown-option {
    background-color: rgb(50, 50, 50);
    border: none !important;
    box-shadow: none !important;
}