.user-profile {
    /* dimentions */
    width: 25px;
    height: 25px;

    /* margins & padding */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* background */
    background-color: rgb(37, 195, 121);

    /* border */
    border-radius: 100%;
    
    /* font-color */
    color: white;
}

    .user-profile:hover {
        text-decoration: none;
    }


    .user-guest {
        display: flex;
    }

    .user-guest button{
        color: black;
        background: rgb(105, 235, 105);
        margin-left: 0.5rem;
        padding: 0.8rem;
        border-radius: 4px;
    }




    .user-profile {
        position: relative;
        cursor: pointer;
      }
      
      .user-menu {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        min-width: 200px;
        border-radius: 5px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        pointer-events: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      
      .user-menu.show {
        opacity: 1;
        pointer-events: auto;
      }
      
      .user-menu-item {
        padding: 12px 16px;
        cursor: pointer;
        color: black;
      }

      .user-menu-item:hover {
        background: rgb(30, 231, 151);
      }

.button-link {
  /* dimentions */
  width: auto;
  height: auto;
  text-decoration: none!important;
}

.login-button {
  /* dimentions */
  width: auto;
  height: 25px;

  /* flex options */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* padding */
  padding: 0px;

  /* background */
  background-color: rgb(0, 132, 255) !important;
  box-shadow: none;

  /* font */
  color: var(--strong-text) !important;
  font-size: 12px;
}

.register-button {

  /* dimentions */
  width: auto;
  height: 25px;

  /* flex options */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* background */
  background-color: rgba(0, 255, 153, 0) !important;
  box-shadow: none;

  /* border */
  border: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.315);

  /* padding */
  padding: 0px;

  /* font */
  color: var(--weak-text) !important;
  font-size: 12px;
}