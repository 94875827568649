.menu-and-sandbox-container {
    /* dimentions */
    width: 100%;
    height: 100%;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    /* no select */
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

input[type=range] {
    
    -webkit-appearance: none;

    /* dimentions */
    width: 100%;
    height: 2px;

    /* margins */
    margin-top: 7px;
    margin-bottom: 7px;

    /* padding */
    padding: 0;
    
    /* background */
    background: rgba(0, 0, 0, 0.15);
    outline: none;

    /* transitions */
    -webkit-transition: .2s;
    transition: opacity .2s;

    cursor: pointer;
    }
    
    input[type=range]:hover {
        opacity: 1;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 5px;
        height: 15px;
        background: #0077ff;
        cursor: pointer;
    }
    
    input[type=range]::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 5px;
        height: 15px;
        background: #0077ff;
        border-radius: 0;
        border: none;
        cursor: pointer;
    }

    input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type="number"] {
        -moz-appearance: textfield;
    }