.sandbox {
    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: 100%;

    /* overflow */
    overflow: hidden;

    /* background-color: var(--sandbox-background); */
}

#light .sandbox {
    background-color: white;
}

#dark .sandbox {
    background-color: var(--sandbox-background);
}

.sandbox-zoom-container-absolute {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.sandbox-zoom-container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.sandbox-elements-wrapper {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

    .sandbox-grid {
        /* position */
        position: absolute;
        left: 0px;
        top: 0px;

        /* dimentions */
        width: 100%;
        height: 100%;
    }

    .sandbox-connections {
        /* position */
        position: absolute;
        left: 0px;
        top: 0px;

        /* dimentions */
        width: 100%;
        height: 100%;
    }

    .sandbox-nodes {
        /* position */
        position: absolute;
        left: 0px;
        top: 0px;

        /* dimentions */
        width: 100%;
        height: 100%;
    }

    .sandbox-nodemap-name {
        /* position */
        position: absolute;
        left: 0px;
        top: 20px;

        /* dimentions */
        width: 100%;
        height: auto;

        /* font */
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: var(--weak-text);
    }

    .loading-sandbox {
        position: absolute;
        width: auto;
        z-index: 9999;
        right: 50%;
        top: 50%;
    }