.input-variables-container,
.output-variables-container {

    /* dimentions */
    width: 100%;
    height: auto;

    /* margins */
    margin-top: 15px;
    margin-bottom: 5px;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-variables,
.output-variables {

    /* dimentions */
    width: 100%;
    height: auto;

    /* margins */
    margin-bottom: 0px;
}

.input-variables-section-title,
.output-variables-section-title {

    /* dimentions */
    width: 100%;

    /* margins */
    margin-bottom: 10px;

    /* font options */
    font-size: 12px;
    color: var(--weak-text);
    text-align: left;
}

.add-input-variable-button,
.add-output-variable-button {

    /* flex options */
    display: flex;
    flex-direction: row;

    opacity: 0.5;
}

.add-input-variable-button:hover,
.add-output-variable-button:hover {

    cursor: pointer;
    opacity: 0.75;
}

    .add-input-variable-button-icon,
    .add-output-variable-button-icon {

        /* dimentions */
        width: 20px;
        height: 20px;

        /* margins */
        margin-right: 2px;
    }

    .add-input-variable-button-text,
    .add-output-variable-button-text {

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        /* font */
        color: var(--text-strong);
        font-size: 12px;
    }