.slider-container {

        /* dimentions */
        width: auto;
        height: auto;

        /* padding */
        padding: 5px;

        /* flex-options */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;
}