/* special scrollbar */

/* width */
.chat-menu-messages-scroll::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
.chat-menu-messages-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
}

/* Handle */
.chat-menu-messages-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
.chat-menu-messages-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.chat-menu {
    
    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

    .chat-menu-label-and-clear-container {

        /* dimentions */
        width: 100%;
        height: auto;

        /* margins */
        margin-bottom: 10px;

        /* flex options */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

        .chat-menu-label {

            /* font */
            color: var(--chat-menu-label);
            font-size: 14px;
        }

        .chat-menu-clear-history-button {

            /* dimentions */
            width: auto;
            height: auto;

            /* padding */
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;

            /* background */
            border-radius: 25px;
            background-color: var(--clear-chat-history-button-background);

            /* font */
            font-size: 14px;
            color:var(--medium-text);

            /* pre hover */
            opacity: 0.5;
        }

            .chat-menu-clear-history-button:hover {
                /* post hover */
                opacity: 1;
                cursor: pointer;
                color: rgba(255, 100, 100, 1);
            }

    .chat-menu-messages-container {

        /* position */
        position: relative;

        /* dimentions */
        width: 100%;
        height: auto;
        min-height: 400px;
        flex-grow: 1;
        overflow: hidden;

        /* margins */
        margin-bottom: 15px;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        /* border */
        border-radius: 10px;

        /* background */
        background-color: var(--chat-menu-messages-container-background);
        box-shadow: var(--chat-menu-messages-container-shadow);

    }

        .chat-menu-messages-scroll {

            /* position */
            position: absolute;
            overflow-y: auto;
        
            /* dimensions */
            width: 100%;
            height: 100%;

            /* flex options */
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        
            /* Add this block for the fade effect */
            padding-top: 10px;
            box-sizing: border-box;

            mask-image: linear-gradient(to top, black calc(100% - 75px), transparent 100%);
        }

            .chat-menu-messages {

                /* position */
                position: relative;

                /* margin */
                margin-top: auto;

                /* dimentions */
                width: 100%;
                height: auto;
            }

                .chat-message-container {

                    /* position */
                    position: relative;

                    /* dimentions */
                    width: 100%;
                    height: auto;

                    /* padding */
                    padding: 15px;

                    /* flex options */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    /* background */
                    background-color: var(--chat-message-container-background);

                    /* border */
                    border-top: solid;
                    border-top-width: 1px;
                    border-top-color: var(--chat-message-container-border-top);
                }

                .chat-message-container-ai {

                    /* background */
                    background-color: var(--chat-message-container-background-ai);

                    /* flex options */;
                    align-items: flex-start;

                    /* border */
                    border-top: none;
                }

                    .chat-message-name-time-container {
                        /* dimentions */
                        width: auto;

                        /* flex options */
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }

                        .chat-message-time {
                            /* margins */
                            margin-left: 5px;
                            margin-bottom: 10px;

                            /* font */
                            color: var(--chat-menu-time-text);
                            font-size: 11px;
                        }
                        
                        .chat-message-name {
                            /* margins */
                            margin-bottom: 10px;

                            /* font */
                            color: var(--chat-menu-role-text);
                            font-size: 11px;
                        }

                    .chat-message-content {
                        /* dimentions */
                        width: 100%;

                        /* padding */
                        padding-left: 10px;

                        /* font */
                        color: var(--chat-menu-message-text);
                        font-size: 14px;
                        overflow-wrap: break-word;
                    }

                        .chat-message-image {
                            /* dimentions */    width: 100%;
                            /* border */        border-radius: 5px;
                            /* color */         filter: invert(0);
                            /* opacity */       opacity: 0.75;
                        }

                        .chat-message-audio {
                            /* dimentions */    min-width: 100%; max-width: 100%; height: 25px;
                            /* border */        border-radius: 5px;
                            /* color */         filter: invert(0.97);
                            /* opacity */       opacity: 0.75;
                        }

    .chat-menu-submit-container {
        /* position */
        position: relative;

        /* dimentions */
        width: 100%;
        height: auto;

        /* border */
        border-radius: 10px;

        /* background */
        background-color: var(--chat-menu-submit-container-background);
        box-shadow: var(--chat-menu-submit-container-shadow);
    }

        .chat-menu-submit-textbox {
            /* dimentions */
            width: 100%;
            height: auto;
            min-height: 75px;

            /* padding */
            padding: 10px;
            padding-bottom: 20px;

            /* border */
            border-radius: 10px;
            border: none;
            outline: none;

            /* font */
            color: var(--text-medium);
            font-size: 14px;

            /* resize and scroll */
            overflow: hidden;
            resize: none;
        }

        .chat-menu-submit-button {
            /* position */
            position: absolute;
            right: 5px;
            bottom: 5px;

            /* dimentions */
            width: 25px;
            height: 25px;

            /* pre-hover */
            opacity: 0.5;
        }

            .chat-menu-submit-button:hover {
                cursor: pointer;
                opacity: 1;
            }

/* WHEN THERE IS NO CHAT HISTORY TO DISPLAY */

.chat-history-explainer-div {
    /* dimentions */    width: 100%; height: auto;
    /* margins */       margin-bottom: 35px;
    /* flex options */  display: flex; flex-direction: row; justify-content: center; align-items: center;
    /* font options */  color: rgba(256, 256, 256, 0.25);
}