.log-element {
    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    
    /* border */
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

    .log-string {
        /* font */
        font-size: 12px;
    }